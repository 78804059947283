import { graphql, useStaticQuery } from "gatsby";

export const useContactQuery = () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      wpPage(id: { eq: "cG9zdDoxOQ==" }) {
        contactCustomFields {
          contactParagraph1
          contactParagraph1Pl
          contactParagraph2
          contactParagraph2Pl
          contactParagraph3
          contactParagraph3Pl
          contactParagraph4
          contactParagraph4Pl
          contactTitle
          contactTitlePl
        }
      }
    }
  `);

  return data;
};
