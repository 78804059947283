import React, { useContext } from "react"

import { GlobalStateContext } from "../../context/GlobalContextProvider"
import { useContactQuery } from "../../hooks/contact/useContactQuery"
import { translateContent } from "../../utils/translate-content"

export const Contact = () => {
  const { language } = useContext(GlobalStateContext)

  const {
    wpPage: {
      contactCustomFields: {
        contactParagraph1,
        contactParagraph1Pl,
        contactParagraph2,
        contactParagraph2Pl,
        contactParagraph3,
        contactParagraph3Pl,
        contactParagraph4,
        contactParagraph4Pl,
        contactTitle,
        contactTitlePl
      }
    }
  } = useContactQuery()

  return (
    <div className="content-wrapper contact">
      <h2 className="content-wrapper__header">{translateContent(language, contactTitle, contactTitlePl)}</h2>
      <div className="contact__container">
        <p>{translateContent(language, contactParagraph1, contactParagraph1Pl)}</p>
        <p>{translateContent(language, contactParagraph2, contactParagraph2Pl)}</p>
        <p>{translateContent(language, contactParagraph3, contactParagraph3Pl)}</p>
        <p>{translateContent(language, contactParagraph4, contactParagraph4Pl)}</p>
      </div>
    </div>
  )
}
